export const picsv2 = {
  base: "https://nazeerholmes.com/images/",
  mainBkg: "Climbing1410.jpg",
  mainBkg_comp: "Climbing1410_comp.jpg",
  pics: [
    "https://nazeerholmes.com/images/1_2_3_4_where_did_all_the_climbers_go-623.jpg",
    "https://nazeerholmes.com/images/AirPodPainting.jpg",
    "https://nazeerholmes.com/images/AlleyFireEscape.jpg",
    "https://nazeerholmes.com/images/AmputadedMon.jpg",
    "https://nazeerholmes.com/images/BacklitMeg.jpg",
    "https://nazeerholmes.com/images/Badass_Mother_Fucker.jpg",
    "https://nazeerholmes.com/images/Black_and_purple_ and_blue_and_white.jpg",
    "https://nazeerholmes.com/images/BrandedHorse.jpg",
    "https://nazeerholmes.com/images/Chinatown.jpg",
    "https://nazeerholmes.com/images/ChinseRestraunt.jpg",
    "https://nazeerholmes.com/images/Climbing1410.jpg",
    "https://nazeerholmes.com/images/Cole_in_the_Box.jpg",
    "https://nazeerholmes.com/images/Couple_of_Rappers.jpg",
    "https://nazeerholmes.com/images/DCDisappeared.jpg",
    "https://nazeerholmes.com/images/Dancer.jpg",
    "https://nazeerholmes.com/images/David@Holys-3.jpg",
    "https://nazeerholmes.com/images/DavidHolys-1.jpg",
    "https://nazeerholmes.com/images/Day_To_Night.jpg",
    "https://nazeerholmes.com/images/Edgecumb.jpg",
    "https://nazeerholmes.com/images/FastStop.jpg",
    "https://nazeerholmes.com/images/Fauni.jpg",
    "https://nazeerholmes.com/images/FireMon.jpg",
    "https://nazeerholmes.com/images/GhostlyCave.jpg",
    "https://nazeerholmes.com/images/Gold&BlueJonte.jpg",
    "https://nazeerholmes.com/images/HCR_Barn.jpg",
    "https://nazeerholmes.com/images/House_Show-161.jpg",
    "https://nazeerholmes.com/images/Instagram-30.jpg",
    "https://nazeerholmes.com/images/Instagram-31.jpg",
    "https://nazeerholmes.com/images/JPPavillion.jpg",
    "https://nazeerholmes.com/images/JackRaps.jpg",
    "https://nazeerholmes.com/images/Jack_in_the_Square.jpg",
    "https://nazeerholmes.com/images/Jack_on_the_box.jpg",
    "https://nazeerholmes.com/images/JamBoys.jpg",
    "https://nazeerholmes.com/images/JimMorrison.jpg",
    "https://nazeerholmes.com/images/KevinKillsLionfish-1.jpg",
    "https://nazeerholmes.com/images/LightTrails1.jpg",
    "https://nazeerholmes.com/images/Light_beam.jpg",
    "https://nazeerholmes.com/images/Little_Lizard-1.jpg",
    "https://nazeerholmes.com/images/LonelyCole.jpg",
    "https://nazeerholmes.com/images/LongExposedRyan.jpg",
    "https://nazeerholmes.com/images/MainRoom.jpg",
    "https://nazeerholmes.com/images/Manacin.jpg",
    "https://nazeerholmes.com/images/MilkyWay.jpg",
    "https://nazeerholmes.com/images/MusicalLight.jpg",
    "https://nazeerholmes.com/images/MustyMan.jpg",
    "https://nazeerholmes.com/images/NoahCOol.jpg",
    "https://nazeerholmes.com/images/NoahThroughtTheBars.jpg",
    "https://nazeerholmes.com/images/NotTheMcQueens.jpg",
    "https://nazeerholmes.com/images/PapAndGang.jpg",
    "https://nazeerholmes.com/images/PapGod.jpg",
    "https://nazeerholmes.com/images/ParisPlayin.jpg",
    "https://nazeerholmes.com/images/ParisReflects.jpg",
    "https://nazeerholmes.com/images/PigPen.jpg",
    "https://nazeerholmes.com/images/Qui.jpg",
    "https://nazeerholmes.com/images/RedForWomen.jpg",
    "https://nazeerholmes.com/images/RockSkull.jpg",
    "https://nazeerholmes.com/images/RyanIsOpen.jpg",
    "https://nazeerholmes.com/images/SInkhole-2-15.jpg",
    "https://nazeerholmes.com/images/SInkhole-2.jpg",
    "https://nazeerholmes.com/images/San_diegoBNB.jpg",
    "https://nazeerholmes.com/images/Sandstone_Mound.jpg",
    "https://nazeerholmes.com/images/SitkaHomes.jpg",
    "https://nazeerholmes.com/images/SmokingBananas.JPG",
    "https://nazeerholmes.com/images/SophiesValentine.jpg",
    "https://nazeerholmes.com/images/Stoney.jpg",
    "https://nazeerholmes.com/images/SuperSlideTree.jpg",
    "https://nazeerholmes.com/images/TWood.jpg",
    "https://nazeerholmes.com/images/TheSmokeyBuildingtif.jpg",
    "https://nazeerholmes.com/images/TiltedStairs.jpg",
    "https://nazeerholmes.com/images/TonyTone.jpg",
    "https://nazeerholmes.com/images/TonysSalon.jpg",
    "https://nazeerholmes.com/images/TorrePines.jpg",
    "https://nazeerholmes.com/images/Trains_Cargo.jpg",
    "https://nazeerholmes.com/images/Tran.jpg",
    "https://nazeerholmes.com/images/TriangleStaircase.jpg",
    "https://nazeerholmes.com/images/Tristans_Family-1.jpg",
    "https://nazeerholmes.com/images/TwoIdiots_Jeremy.jpg",
    "https://nazeerholmes.com/images/TyPullUp.jpg",
    "https://nazeerholmes.com/images/What_is_a_Valentine-1367.jpg",
    "https://nazeerholmes.com/images/What_is_a_Valentine-811.jpg",
    "https://nazeerholmes.com/images/Wheelchair_man-367.jpg",
    "https://nazeerholmes.com/images/Woman_and_her_Fruit.jpg",
    "https://nazeerholmes.com/images/WoodMan.jpg",
    "https://nazeerholmes.com/images/YosemiteFalls.jpg",
    "https://nazeerholmes.com/images/ZachsUnlevel.jpg",
    "https://nazeerholmes.com/images/climb_n_rap-611.jpg",
    "https://nazeerholmes.com/images/dead_tree_and_bird_feeders.jpg",
    "https://nazeerholmes.com/images/kinkaid-30.jpg",
    "https://nazeerholmes.com/images/lil_boats.jpg",
    "https://nazeerholmes.com/images/opposites.jpg",
    "https://nazeerholmes.com/images/snareAndBass.jpg",
    "https://nazeerholmes.com/images/untitled-268-1.jpg"
  ],
  mins: [
    "https://nazeerholmes.com/images/minimized/1_2_3_4_where_did_all_the_climbers_go-623_min.jpg",
    "https://nazeerholmes.com/images/minimized/AirPodPainting_min.jpg",
    "https://nazeerholmes.com/images/minimized/AlleyFireEscape_min.jpg",
    "https://nazeerholmes.com/images/minimized/AmputadedMon_min.jpg",
    "https://nazeerholmes.com/images/minimized/BacklitMeg_min.jpg",
    "https://nazeerholmes.com/images/minimized/Badass_Mother_Fucker_min.jpg",
    "https://nazeerholmes.com/images/minimized/Black_and_purple_ and_blue_and_white_min.jpg",
    "https://nazeerholmes.com/images/minimized/BrandedHorse_min.jpg",
    "https://nazeerholmes.com/images/minimized/Chinatown_min.jpg",
    "https://nazeerholmes.com/images/minimized/ChinseRestraunt_min.jpg",
    "https://nazeerholmes.com/images/minimized/Climbing1410_min.jpg",
    "https://nazeerholmes.com/images/minimized/Cole_in_the_Box_min.jpg",
    "https://nazeerholmes.com/images/minimized/Couple_of_Rappers_min.jpg",
    "https://nazeerholmes.com/images/minimized/DCDisappeared_min.jpg",
    "https://nazeerholmes.com/images/minimized/Dancer_min.jpg",
    "https://nazeerholmes.com/images/minimized/David@Holys-3_min.jpg",
    "https://nazeerholmes.com/images/minimized/DavidHolys-1_min.jpg",
    "https://nazeerholmes.com/images/minimized/Day_To_Night_min.jpg",
    "https://nazeerholmes.com/images/minimized/Edgecumb_min.jpg",
    "https://nazeerholmes.com/images/minimized/FastStop_min.jpg",
    "https://nazeerholmes.com/images/minimized/Fauni_min.jpg",
    "https://nazeerholmes.com/images/minimized/FireMon_min.jpg",
    "https://nazeerholmes.com/images/minimized/GhostlyCave_min.jpg",
    "https://nazeerholmes.com/images/minimized/Gold&BlueJonte_min.jpg",
    "https://nazeerholmes.com/images/minimized/HCR_Barn_min.jpg",
    "https://nazeerholmes.com/images/minimized/House_Show-161_min.jpg",
    "https://nazeerholmes.com/images/minimized/Instagram-30_min.jpg",
    "https://nazeerholmes.com/images/minimized/Instagram-31_min.jpg",
    "https://nazeerholmes.com/images/minimized/JPPavillion_min.jpg",
    "https://nazeerholmes.com/images/minimized/JackRaps_min.jpg",
    "https://nazeerholmes.com/images/minimized/Jack_in_the_Square_min.jpg",
    "https://nazeerholmes.com/images/minimized/Jack_on_the_box_min.jpg",
    "https://nazeerholmes.com/images/minimized/JamBoys_min.jpg",
    "https://nazeerholmes.com/images/minimized/JimMorrison_min.jpg",
    "https://nazeerholmes.com/images/minimized/KevinKillsLionfish-1_min.jpg",
    "https://nazeerholmes.com/images/minimized/LightTrails1_min.jpg",
    "https://nazeerholmes.com/images/minimized/Light_beam_min.jpg",
    "https://nazeerholmes.com/images/minimized/Little_Lizard-1_min.jpg",
    "https://nazeerholmes.com/images/minimized/LonelyCole_min.jpg",
    "https://nazeerholmes.com/images/minimized/LongExposedRyan_min.jpg",
    "https://nazeerholmes.com/images/minimized/MainRoom_min.jpg",
    "https://nazeerholmes.com/images/minimized/Manacin_min.jpg",
    "https://nazeerholmes.com/images/minimized/MilkyWay_min.jpg",
    "https://nazeerholmes.com/images/minimized/MusicalLight_min.jpg",
    "https://nazeerholmes.com/images/minimized/MustyMan_min.jpg",
    "https://nazeerholmes.com/images/minimized/NoahCOol_min.jpg",
    "https://nazeerholmes.com/images/minimized/NoahThroughtTheBars_min.jpg",
    "https://nazeerholmes.com/images/minimized/NotTheMcQueens_min.jpg",
    "https://nazeerholmes.com/images/minimized/PapAndGang_min.jpg",
    "https://nazeerholmes.com/images/minimized/PapGod_min.jpg",
    "https://nazeerholmes.com/images/minimized/ParisPlayin_min.jpg",
    "https://nazeerholmes.com/images/minimized/ParisReflects_min.jpg",
    "https://nazeerholmes.com/images/minimized/PigPen_min.jpg",
    "https://nazeerholmes.com/images/minimized/Qui_min.jpg",
    "https://nazeerholmes.com/images/minimized/RedForWomen_min.jpg",
    "https://nazeerholmes.com/images/minimized/RockSkull_min.jpg",
    "https://nazeerholmes.com/images/minimized/RyanIsOpen_min.jpg",
    "https://nazeerholmes.com/images/minimized/SInkhole-2-15_min.jpg",
    "https://nazeerholmes.com/images/minimized/SInkhole-2_min.jpg",
    "https://nazeerholmes.com/images/minimized/San_diegoBNB_min.jpg",
    "https://nazeerholmes.com/images/minimized/Sandstone_Mound_min.jpg",
    "https://nazeerholmes.com/images/minimized/SitkaHomes_min.jpg",
    "https://nazeerholmes.com/images/minimized/SmokingBananas_min.jpg",
    "https://nazeerholmes.com/images/minimized/SophiesValentine_min.jpg",
    "https://nazeerholmes.com/images/minimized/Stoney_min.jpg",
    "https://nazeerholmes.com/images/minimized/SuperSlideTree_min.jpg",
    "https://nazeerholmes.com/images/minimized/TWood_min.jpg",
    "https://nazeerholmes.com/images/minimized/TheSmokeyBuildingtif_min.jpg",
    "https://nazeerholmes.com/images/minimized/TiltedStairs_min.jpg",
    "https://nazeerholmes.com/images/minimized/TonyTone_min.jpg",
    "https://nazeerholmes.com/images/minimized/TonysSalon_min.jpg",
    "https://nazeerholmes.com/images/minimized/TorrePines_min.jpg",
    "https://nazeerholmes.com/images/minimized/Trains_Cargo_min.jpg",
    "https://nazeerholmes.com/images/minimized/Tran_min.jpg",
    "https://nazeerholmes.com/images/minimized/TriangleStaircase_min.jpg",
    "https://nazeerholmes.com/images/minimized/Tristans_Family-1_min.jpg",
    "https://nazeerholmes.com/images/minimized/TwoIdiots_Jeremy_min.jpg",
    "https://nazeerholmes.com/images/minimized/TyPullUp_min.jpg",
    "https://nazeerholmes.com/images/minimized/What_is_a_Valentine-1367_min.jpg",
    "https://nazeerholmes.com/images/minimized/What_is_a_Valentine-811_min.jpg",
    "https://nazeerholmes.com/images/minimized/Wheelchair_man-367_min.jpg",
    "https://nazeerholmes.com/images/minimized/Woman_and_her_Fruit_min.jpg",
    "https://nazeerholmes.com/images/minimized/WoodMan_min.jpg",
    "https://nazeerholmes.com/images/minimized/YosemiteFalls_min.jpg",
    "https://nazeerholmes.com/images/minimized/ZachsUnlevel_min.jpg",
    "https://nazeerholmes.com/images/minimized/climb_n_rap-611_min.jpg",
    "https://nazeerholmes.com/images/minimized/dead_tree_and_bird_feeders_min.jpg",
    "https://nazeerholmes.com/images/minimized/kinkaid-30_min.jpg",
    "https://nazeerholmes.com/images/minimized/lil_boats_min.jpg",
    "https://nazeerholmes.com/images/minimized/opposites_min.jpg",
    "https://nazeerholmes.com/images/minimized/snareAndBass_min.jpg",
    "https://nazeerholmes.com/images/minimized/untitled-268-1_min.jpg"
  ]
}